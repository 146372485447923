import React from 'react';
import './Footer.css'

const Footer: React.FC = () => {
    return (
        <>
            <div className="container-fluid pt-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-11">
                        <div className="row" style={{ borderBottom: "1px solid lightgray" }}>
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                <h4 className='fw-bold text-primary'>DoWrite</h4>
                                <div className='d-lg-block d-md-block d-sm-none d-none'>
                                <p className='mt-3 text-secondary '>DotWrite is the world's best AI writing platform for creating SEO-optimized content</p>
                                <p className='mt-5 d-flex justify-content-between'>
                                    <i className="fa-brands fa-instagram text-dark fs-5"></i>
                                    <i className="fa-brands fa-facebook text-dark fs-5"></i>
                                    <i className="fa-brands fa-x-twitter text-dark fs-5"></i>
                                    <i className="fa-brands fa-linkedin text-dark fs-5"></i>
                                </p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                <h4 className='fw-bold'>Tools</h4>
                                <div className='d-lg-block d-md-block d-sm-none d-none'>
                                    <p className='mt-3 text-secondary'>AI Article Writer</p>
                                    <p className='mt-3 text-secondary'>Paraphrasing Tool</p>
                                    <p className='mt-3 text-secondary'>Sentence Expander</p>
                                    <p className='mt-3 text-secondary'>Text Summarizer</p>
                                    <p className='mt-3 text-secondary'>Landing Page Generator</p>
                                    <p className='mt-3 text-secondary'>Product Desc Generator</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                <h4 className='fw-bold'>Free tools</h4>
                                <div className='d-lg-block d-md-block d-sm-none d-none'>
                                    <p className='mt-3 text-secondary'>Chatsonic - AI Chat</p>
                                    <p className='mt-3 text-secondary'>Photosonic -AI Image</p>
                                    <p className='mt-3 text-secondary'>Product name Generator</p>
                                    <p className='mt-3 text-secondary'>Call to action Generator</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                <h4 className='fw-bold'>Resources</h4>
                                <div className='d-lg-block d-md-block d-sm-none d-none'>
                                    <p className='mt-3 text-secondary'>Facebook Group</p>
                                    <p className='mt-3 text-secondary'>Blog</p>
                                    <p className='mt-3 text-secondary'>Guideline and Tutorials</p>
                                    <p className='mt-3 text-secondary'>API Access</p>
                                    <p className='mt-3 text-secondary'>Status</p>
                                    <p className='mt-3 text-secondary'>Free Templates</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                <h4 className='fw-bold'>Compare</h4>
                                <div className='d-lg-block d-md-block d-sm-none d-none'>
                                    <p className='mt-3 text-secondary'>Jasper</p>
                                    <p className='mt-3 text-secondary'>Copy.AI</p>
                                    <p className='mt-3 text-secondary'>Rytr.AI</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                <h4 className='fw-bold'>Company</h4>
                                <div className='d-lg-block d-md-block d-sm-none d-none'>
                                    <p className='mt-3 text-secondary'>Wall of Love</p>
                                    <p className='mt-3 text-secondary'>Contact Us</p>
                                    <p className='mt-3 text-secondary'>About Us</p>
                                    <p className='mt-3 text-secondary'>Careers</p>
                                    <p className='mt-3 text-secondary'>Pricing</p>
                                    <p className='mt-3 text-secondary'>Enterprise & Changelog</p>
                                </div>
                            </div>
                        </div>
                        <div className='row m-3'>
                            <div className='col-lg-3 col-md-3 col-sm-12 col-sm-6 fw-bold text-secondary'>Terms</div>
                            <div className='col-lg-3 col-md-3 col-sm-12 col-sm-6 fw-bold text-secondary'>Privacy Policy</div>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-sm-12 d-flex justify-content-end footer-rights'>
                                <p className='fw-bold text-secondary'>2022 UNIAL SOLUTIONS, Inc, All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
