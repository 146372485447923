import React from 'react';
import { Routes, Route } from 'react-router-dom'
import Home from './Home/Home';
// import Home from './Frontend/Home/Home';

const Index: React.FC = () => {
    return (
        <>
            <Routes>
                <Route path='/' element={<Home />} />
            </Routes>
        </>
    );
};

export default Index;
