import React, { useState } from 'react';
import './Faq.css'

const Faq: React.FC = () => {
    const [activeAccordian, setactiveAccordian] = useState("What is DoWrite?")

    const accordianData = [
        {
            title: 'What is DoWrite?',
            desc: "DoWrite is the world's best AI writing platform for creating SEO optimized content that increases organic traffic to your website on Google. By strategically placing keywords, internal links, external links in your generated content, you can increase traffic, sales and revenue 10X"
        },
        {
            title: 'is DoWrite free?',
            desc: "DoWrite is the world's best AI writing platform for creating SEO optimized content that increases organic traffic to your website on Google. By strategically placing keywords, internal links, external links in your generated content, you can increase traffic, sales and revenue 10X"
        },
        {
            title: 'Which language does DoWrite support?',
            desc: "DoWrite is the world's best AI writing platform for creating SEO optimized content that increases organic traffic to your website on Google. By strategically placing keywords, internal links, external links in your generated content, you can increase traffic, sales and revenue 10X"
        },
        {
            title: 'Who can use DoWrite?',
            desc: "DoWrite is the world's best AI writing platform for creating SEO optimized content that increases organic traffic to your website on Google. By strategically placing keywords, internal links, external links in your generated content, you can increase traffic, sales and revenue 10X"
        },
    ]
    return (
        <>
            <div className="container-fluid">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-10 col-md-11 col-sm-11 col-11">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 mt-5 d-flex justify-content-center align-items-center">
                                <div className='left-card w-100 m-4 p-3'>
                                    <div className='faq-card1 shadow'>
                                        <div className='d-flex gap-3 p-3' style={{ backgroundColor: "#f1f1f1", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                            <i className="fa-solid fa-bold faq-card1-icon"></i>
                                            <i className="fa-solid fa-italic faq-card1-icon"></i>
                                            <i className="fa-solid fa-underline faq-card1-icon"></i>
                                            <i className="fa-solid fa-h faq-card1-icon"></i>
                                            <i className="fa-solid fa-list faq-card1-icon"></i>
                                            <i className="fa-solid fa-list faq-card1-icon"></i>
                                        </div>
                                        <div className='p-2'>
                                            <p style={{ fontSize: '11px', fontWeight: 'bold', }}>The role of AI Content Creation for Marketing Purpose</p>
                                            <p style={{ fontSize: '11px', color: "gray" }}>You might to be wondering about what is the big deal about Artificial intelligence?</p>
                                        </div>
                                    </div>

                                    <div className='faq-card2 shadow'>
                                        <div className='d-flex gap-3 p-3' style={{ backgroundColor: "#f1f1f1", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                            <i className="fa-solid fa-bold faq-card1-icon"></i>
                                            <i className="fa-solid fa-italic faq-card1-icon"></i>
                                            <i className="fa-solid fa-underline faq-card1-icon"></i>
                                            <i className="fa-solid fa-h faq-card1-icon"></i>
                                            <i className="fa-solid fa-list faq-card1-icon"></i>
                                            <i className="fa-solid fa-list faq-card1-icon"></i>
                                        </div>
                                        <div className='p-2'>
                                            <p style={{ fontSize: '11px', color: "gray" }}>You might to be wondering about what is the big deal about Artificial intelligence?</p>
                                        </div>
                                    </div>

                                    <div className='bg-white shadow' style={{ width: "100%", borderRadius: "20px", marginTop: '100px', marginBottom: "100px" }}>
                                        <div className='p-4'>
                                            <div className='d-flex gap-3'>
                                                <i className="fa-solid fa-pen text-primary"></i>
                                                <i className="fa-regular fa-bookmark text-secondary"></i>
                                                <i className="fa-regular fa-clone text-secondary"></i>
                                                <i className="fa-regular fa-calendar-plus text-secondary"></i>
                                            </div>
                                            <p className='mt-3 mb-1 fw-bold'>R.F Kuang's The Poppy Wars</p>
                                            <p className='mb-0 text-secondary'>Many students define paragraphs in terms of length: a paragraph is a group of at least five sentences, a paragraph is half a page long, etc.Many students define paragraphs in terms of length: a paragraph is a group of at least five sentences, a paragraph is half a page long, etc.</p>
                                        </div>
                                        <div style={{ borderTop: "1px solid lightgray", padding: "10px 20px 10px 20px" }}>
                                            111 words 641 characters
                                        </div>
                                    </div>

                                    <div className='faq-card3 shadow'>
                                        <div className='d-flex gap-3 p-3' style={{ backgroundColor: "#f1f1f1", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                            <i className="fa-solid fa-bold faq-card1-icon"></i>
                                            <i className="fa-solid fa-italic faq-card1-icon"></i>
                                            <i className="fa-solid fa-underline faq-card1-icon"></i>
                                            <i className="fa-solid fa-h faq-card1-icon"></i>
                                            <i className="fa-solid fa-list faq-card1-icon"></i>
                                            <i className="fa-solid fa-list faq-card1-icon"></i>
                                        </div>
                                        <div className='p-2'>
                                            <p style={{ fontSize: '11px', color: "gray", marginBottom: "0px" }}>You might to be wondering about what is the big deal about Artificial intelligence?Many students define paragraphs in terms of length: a paragraph is a group of at least five sentences, a paragraph is half a page long, etc.</p>
                                        </div>
                                        <div style={{ fontSize: '11px', borderTop: "1px solid lightgray", padding: "5px 10px 5px 10px", color: "gray" }}>
                                            45 words 258 characters
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-11 col-sm-11 col-11 mt-5 d-flex justify-content-center">
                                <div className='right-card-faq'>
                                    <h1 className='fw-bold'>Frequently asked <br /> questions</h1>
                                    <p className='mt-3 text-secondary'>You got some question about DoWrite? You can check on below about your question</p>

                                    {
                                        accordianData.map((t, i) => (
                                            <div className='mt-3 mb-3'>
                                                <div key={i} className='d-flex justify-content-between pt-2 pb-2' style={{ borderBottom: "1px solid lightgray" }} onClick={() => setactiveAccordian(t.title)}>
                                                    <h4 className='fw-bold'>{t.title}</h4> <i className={`fa-solid ${t.title == activeAccordian ? "fa-angle-up" : 'fa-angle-down'} mt-2 fs-5`}></i>
                                                </div>
                                                {
                                                    t.title == activeAccordian && (
                                                        <div className='pt-3 pb-2 text-secondary'>
                                                            {t.desc}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }

                                    <button className='btn getButton'>See more question</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Faq;
