import React, { useEffect, useState } from 'react';
import './Hero.css'
import image1 from '../../../../assets/images/cartoon1.png'
import image2 from '../../../../assets/images/cartoon2.png'
import image3 from '../../../../assets/images/cartoon3.png'
import image4 from '../../../../assets/images/sidebar2.png'
import CkEditorWrapper from '../CkEditorWrapper/CkEditorWrapper';
import Switch from "react-switch";
import { BackendUrl } from '../../../../App';
import axios from 'axios';
import clipboardCopy from 'clipboard-copy';
const Hero: React.FC = () => {
    const [activebtn, setactivebtn] = useState('GET IDEAS')
    const [activeIndex, setactiveIndex] = useState(0)
    const [getResult, setgetResult] = useState(false)
    const [writeTitle, setwriteTitle] = useState(false)

    const [blogTopic, setblogTopic] = useState('')
    const [companyName, setcompanyName] = useState('')
    const [industry, setindustry] = useState('')
    const [targetAudience, settargetAudience] = useState('')
    const [keyPoints, setkeyPoints] = useState('')
    const [Tone, setTone] = useState('')
    const [desiredLength, setdesiredLength] = useState('')
    const [callAction, setcallAction] = useState('')
    const [contentDetails, setcontentDetails] = useState('')

    const [isLoading, setisLoading] = useState(false)

    const [isCopied, setIsCopied] = useState(false);

    const handleEditorChange = (newContent: string) => {
        setcontentDetails(newContent);
    };

    const btnArray = [
        { title: "GET IDEAS" },
        { title: "GET AN OUTLINE" },
        // { title: "GET AN ARTICLE" },
    ]

    const handleClick = () => {
        if (activeIndex < 1) {
            setactivebtn(btnArray[activeIndex + 1].title)
            setactiveIndex(activeIndex + 1)
        } else {
            setactivebtn(btnArray[0].title)
            setactiveIndex(0)
        }
    }

    const handleGenerate = () => {
        if (!blogTopic) {
            return showError("Enter The Blog's Topic or Title");
        }
        if (!companyName) {
            return showError("Enter The Company Name");
        }
        if (!industry) {
            return showError("Enter About the Industry");
        }
        if (!targetAudience) {
            return showError("Enter The Targeted Audience");
        }
        if (!keyPoints) {
            return showError("Enter The Key Points");
        }
        if (!Tone) {
            return showError("Enter The Tone or Style");
        }
        if (!desiredLength) {
            return showError("Enter The Desired Length");
        }
        if (!callAction) {
            return showError("Enter The CTA");
        }

        function showError(fieldName: string) {
            alert(`Please ${fieldName}`)
        }

        setisLoading(true)

        const prompt = `Generate a blog post for [${companyName}] in the [${industry}] industry.

                Title: "[${blogTopic}]"
                Audience: [${targetAudience}]
                Tone / Style: [${Tone}]
        Word Count: [${desiredLength}] words

        In this blog post, we'll explore [${blogTopic}], diving deep into the [${industry}] industry. Our target audience, [${targetAudience}], will find this content [${Tone}].

        Key Points to Cover:
                [${keyPoints}]

        Throughout the article, emphasize[${companyName}]'s unique strengths and expertise in the [${industry}] sector. Highlight how [${companyName}] can provide value to [${targetAudience}] by addressing their pain points and offering solutions.

        To engage readers further, include a compelling call to action(${callAction}) encouraging them to[${callAction}].This CTA should resonate with the article's theme and provide clear benefits to the reader.

        Ensure the article is well - researched, informative, and tailored to meet[${companyName}]'s content goals. The final output should be approximately [${desiredLength}] words, delivering high-quality content for your target audience.

        Let's get started!
        `

        let arrayData = [{ role: "user", content: prompt }];

        const formData = { prompt: JSON.stringify(arrayData) }

        axios.post(`${BackendUrl}/askgpt`, formData)
            .then((response) => {
                const paragraphs = response.data.content.split('\n');
                const formattedContent = paragraphs.map((paragraph: string) => `<p>${paragraph}</p>`).join('');
                setcontentDetails(formattedContent)
                setisLoading(false)
                setactivebtn("GET AN OUTLINE")

                setblogTopic("")
                setcompanyName('')
                setindustry("")
                settargetAudience("")
                setkeyPoints("")
                setTone("")
                setdesiredLength("")
                setcallAction("")
            }).catch((err) => {
                console.log(err)
                setisLoading(false)
            })
    }

    const handleCopyClick = async () => {
        try {
          await clipboardCopy(contentDetails);
          setIsCopied(true);
        } catch (error) {
          console.error('Error copying text to clipboard:', error);
        }
      };

    return (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center'>
                    <div className='col-lg-10 col-md-11 col-sm-11 col-12'>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center align-items-center" style={{ paddingTop: "80px", position: 'relative' }}>
                                <div className='w-50 hero-title-div'>
                                    <h1 className='hero-title' style={{ fontSize: "51px", fontWeight: 'bold', textAlign: "center" }}>Generate Content Copy Text for Creating <span style={{ color: "#522CAB" }}>Facebook Ads</span></h1>

                                    <p className='pt-3 text-center' style={{ fontSize: "19px", color: "gray" }}>Create SEO-optimized and plagiarism-free content for you blogs, ads, emails, website 10X faster</p>
                                    <div className='d-flex justify-content-center'>
                                        <button className='btn btn-hero mt-3'>Start writing for free</button>
                                    </div>
                                    <div className='pt-3 d-flex justify-content-center gap-2'>
                                        <i className="fa-solid fa-star pt-1" style={{ color: "#FBCC5E" }}></i>
                                        <i className="fa-solid fa-star pt-1" style={{ color: "#FBCC5E" }}></i>
                                        <i className="fa-solid fa-star pt-1" style={{ color: "#FBCC5E" }}></i>
                                        <i className="fa-solid fa-star pt-1" style={{ color: "#FBCC5E" }}></i>
                                        <i className="fa-solid fa-star pt-1" style={{ color: "#FBCC5E" }}></i>
                                        <div style={{ height: '20px', width: "2px", backgroundColor: "gray", marginTop: '4px' }}></div>
                                        <p>5.0 Best AI Copywriter</p>
                                    </div>
                                </div>
                                <div className='hero-img' style={{ position: 'absolute', top: 40, left: 80, backgroundColor: "lightgray", borderRadius: "50%" }}>
                                    <img style={{ height: '100px', width: "100px", transform: 'rotate(-20deg)' }} src={image1} />
                                </div>
                                <div className='hero-img2' style={{ position: 'absolute', top: 70, right: 80, backgroundColor: "lightgray", borderRadius: "50%" }}>
                                    <img style={{ height: '100px', width: "100px", transform: 'rotate(20deg)' }} src={image2} />
                                </div>
                                <div className='hero-img3' style={{ position: 'absolute', bottom: 40, left: 80, backgroundColor: "lightgray", borderRadius: "50%" }}>
                                    <img style={{ height: '100px', width: "100px", transform: 'rotate(-20deg)' }} src={image3} />
                                </div>
                                <div className='hero-img4' style={{ position: 'absolute', bottom: 10, right: 80, backgroundColor: "lightgray", borderRadius: "50%" }}>
                                    <img style={{ height: '100px', width: "100px", transform: 'rotate(20deg)' }} src={image3} />
                                </div>
                            </div>
                        </div>

                        <div className='row mt-4 mb-4'>
                            <div className='col-12'>
                                <div className='p-3 hero-input-div'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-4 col-sm-6 col-6 pt-1 d-lg-block d-md-block d-sm-none d-none'>
                                            <div className='d-flex gap-2'>
                                                <div className='hero-number'>1</div>
                                                <button className={`fw-bold head-title ${activebtn == 'GET IDEAS' && 'text-primary'}`} onClick={() => setactivebtn('GET IDEAS')}>GET IDEAS</button>
                                                <div className='hero-div-line' style={{ width: "75%" }}></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-4 col-sm-6 col-6 pt-1 d-lg-block d-md-block d-sm-none d-none'>
                                            <div className='d-flex gap-2'>
                                                <div className='hero-number'>2</div>
                                                <button className={`fw-bold head-title ${activebtn == 'GET AN OUTLINE' && 'text-primary'}`} onClick={() => setactivebtn('GET AN OUTLINE')}>GET AN OUTLINE</button>
                                                <div className='hero-div-line' style={{ width: "65%" }}></div>
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-2 col-md-4 col-sm-12 pt-1 d-lg-block d-md-block d-sm-none d-none'>
                                            <div className='d-flex  gap-1'>
                                                <div className='hero-number'>3</div>
                                                <button className={`fw-bold head-title ${activebtn == 'GET AN ARTICLE' && 'text-primary'}`} onClick={() => setactivebtn('GET AN ARTICLE')}>GET AN ARTICLE</button>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='row d-lg-none d-md-none d-sm-block d-block'>
                                        <div className='d-flex justify-content-center gap-3' style={{ position: 'relative' }}>
                                            <div className='hero-number mt-1'>{activeIndex + 1}</div> <div className='fw-bold fs-5 text-primary'>{btnArray[activeIndex].title}</div>
                                            <div style={{ position: 'absolute', right: 20, top: 5 }}>
                                                <i className="fa-solid fa-arrow-right fs-5" onClick={handleClick}></i>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        activebtn == 'GET IDEAS' ?
                                            <div className='row mt-4'>
                                                <div className='col-lg-6 col-md-6 col-sm-12'>

                                                    <div className='p-3'>
                                                        <p className='input-text'>Blog Topic <i className="fa-solid fa-circle-info" style={{ color: "lightgray" }}></i></p>
                                                        <input type='text' className='form-control' value={blogTopic} onChange={e => setblogTopic(e.target.value)} placeholder='Enter The Blog Title/Topic' />

                                                        <p className='input-text'>Company Name</p>
                                                        <input type='text' className='form-control' value={companyName} onChange={e => setcompanyName(e.target.value)} placeholder='Enter the Company Name' />

                                                        <p className='input-text'>Industry/Niche</p>
                                                        <input type='text' className='form-control' value={industry} onChange={e => setindustry(e.target.value)} placeholder='Enter the Industry/Niche' />

                                                        <p className='input-text'>Target Audience</p>
                                                        <input type='text' className='form-control' value={targetAudience} onChange={e => settargetAudience(e.target.value)} placeholder='Enter the Target Audience' />


                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 ckeditor-head'>
                                                    <div className='ckeditor-div' style={{ height: "100%", borderLeft: "1px solid lightgray", padding: "0 15px 0 15px" }}>
                                                        <div className='p-3'>
                                                            <p className='input-text'>Key Points/Information to Include</p>
                                                            <input type='text' className='form-control' value={keyPoints} onChange={e => setkeyPoints(e.target.value)} placeholder='Enter The Key Points/Information' />

                                                            <p className='input-text'>Tone/Style</p>
                                                            <input type='text' className='form-control' value={Tone} onChange={e => setTone(e.target.value)} placeholder='(e.g., formal, casual, informative, persuasive)' />

                                                            <p className='input-text'>Desired Length</p>
                                                            <input type='text' className='form-control' value={desiredLength} onChange={e => setdesiredLength(e.target.value)} placeholder=' (word count)' />

                                                            <p className='input-text'>Call to Action</p>
                                                            <input type='text' className='form-control' value={callAction} onChange={e => setcallAction(e.target.value)} placeholder='(if applicable)' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='row mt-4'>
                                                <div className='col-12 ckeditor-head'>
                                                    <div className='ckeditor-div' style={{ height: "100%" }}>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className='fs-4 fw-bold d-flex gap-3'>
                                                                {/* <i className="fa-solid fa-arrow-left mt-2"></i>  */}
                                                                AI Response
                                                            </div>
                                                            <div><i className="fa-solid fa-ellipsis-vertical"></i></div>
                                                        </div>
                                                        <div className='mt-3 '>
                                                            <CkEditorWrapper initialValue={contentDetails} onChange={handleEditorChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                    <div className='p-3 d-flex justify-content-end'>
                                        {
                                            activebtn == 'GET IDEAS' ?
                                                <button className='btn btn-primary herobtn gap-3' onClick={handleGenerate} >
                                                    {isLoading ? "Loading..." : 'Generate Blog'}
                                                </button>
                                                :
                                                <button className='btn btn-primary herobtn gap-3' onClick={handleCopyClick}>
                                                    {isCopied ? 'Copied!' : 'Copy Text'}
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
};

export default Hero;

{/* <div className='mt-2 d-flex justify-content-between'>
    Get related results from Google
    <Switch
        className='small-switch'
        onColor='#3BFFAE'
        checkedIcon={false}
        uncheckedIcon={false}
        checked={getResult}
        onChange={(e) => {
            setgetResult(!getResult)
        }}
    />
</div> */}

{/* <div className='mt-3 p-2' style={{ backgroundColor: "#f4f4f4", borderRadius: "13px" }}>
    <div className='mt-2 d-flex justify-content-between'>
        <span className='text-dark fw-bold'>Write a title yourself</span>
        <Switch
            className='small-switch2'
            onColor='#3BFFAE'
            checkedIcon={false}
            uncheckedIcon={false}
            checked={writeTitle}
            onChange={(e) => {
                setwriteTitle(!writeTitle)
            }}
        />
    </div>
    <p className='mt-2 text-secondary'>
        If this is not selected, Then Tone Masters will independently offer options with the titles
    </p>
</div> */}