import React, { useState } from 'react';
import './Header.css'
import Logo from '../../../../assets/images/Logo1.png'
import { useNavigate } from 'react-router-dom';
const Header: React.FC = () => {
    const navigate = useNavigate();
    const [feature, setfeature] = useState(false)
    const [resources, setresources] = useState(false)

    const [menuVisible, setMenuVisible] = useState(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row border d-flex justify-content-center">
                    <div className="col-lg-11 col-md-11 col-sm-12 col-12">
                        <div className='row p-2'>
                            <div className='col-lg-2 col-md-10 col-sm-10 col-10'>
                                <img src={Logo} height={50} className='d-flex' />
                            </div>
                            <div className='col-lg-10 col-md-2 col-sm-2 col-2 d-flex justify-content-center align-items-center'>
                                <div className="menu-icon d-lg-none" onClick={toggleMenu}>
                                    <i className="fa-solid fa-bars" style={{fontSize:"25px"}}></i>
                                </div>
                                <div className={`nav-center ${menuVisible ? 'show' : ''}`}>
                                    <div className='row'>
                                        <div className='col-lg-8'>
                                            <div className='d-flex gap-5 nav-center mt-1'>
                                                <div className='nav-text'>
                                                    Feature <i className="fa-solid fa-angle-down nav-angle" onClick={() => { setfeature(!feature); setresources(false) }}></i>
                                                    {
                                                        feature &&
                                                        <div className='nav-popup shadow-sm'>No Feature</div>
                                                    }
                                                </div>
                                                <div className='nav-text'>
                                                    Resources <i className="fa-solid fa-angle-down nav-angle" onClick={() => { setresources(!resources); setfeature(false) }}></i>
                                                    {
                                                        resources &&
                                                        <div className='nav-popup shadow-sm'>No Resources</div>
                                                    }
                                                </div>
                                                <div className='nav-text'>Use case</div>
                                                <div className='nav-text'>Pricing</div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4 d-lg-flex d-md-none justify-content-center align-items-center gap-3 nav-end-text'>
                                            <button className='btn btn-login' onClick={()=>navigate('/signup')}>Log in</button>
                                            <button className='btn btn-try'>Try for free</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-lg-4 col-md-4 d-flex justify-content-center align-items-center gap-3'>
                                <button className='btn btn-login'>Log in</button>
                                <button className='btn btn-try'>Try for free</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
