import React from 'react';
import './Profile.css'
import ProfileImage from '../../../../assets/images/profile.png'
const Profile: React.FC = () => {
    return (
        <>
            <div className="container-fluid mt-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-10 col-md-11 col-sm-11 col-11">
                    <div className="row">
                    <div className="col-12">
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <h1 className='fw-bold'>Hear from the DoWrite community</h1>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <p className='text-secondary'>The Future of the writing is here! Let our users show you a glimpse of it. And get some ideas from DoWrite community.</p>
                                <p className='text-primary d-flex gap-3 mb-0'>Write article that rank for free <i className="fa-solid fa-arrow-right mt-1" style={{ transform: "rotate(-45deg)" }}></i></p>
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col-lg-6 col-md-12 d-flex mt-3'>
                                <img src={ProfileImage} style={{ borderRadius: "10px", width: '80%' }} />
                            </div>
                            <div className='col-lg-6 d-flex align-items-center mt-3'>
                                <div>
                                    <h2 className='fw-bold'>I've gone from writing one article a day to DoWrite</h2>
                                    <p className='text-secondary mt-2 mb-2'>I just told Article Writer 3.0 what I wanted to write about, and it generated serveral introductions, followed by outlines, and finally, a pretty solid article. This tool is super helpful!</p>
                                    <img className='mt-3' src={ProfileImage} style={{ borderRadius: "50%", width: '50px', height: "50px" }} />
                                    <h5 className='mt-2'>Albert Flores</h5>
                                    <p className='text-secondary'>Marketing Guide & Influencer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
