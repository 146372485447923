import React from 'react';
import './Ads.css'

const Ads: React.FC = () => {

    const btnArray = [
        { title: "Linkedin Ads" },
        { title: "Facebook Ads" },
        { title: "Google Ads" },
        { title: "SEO Meta Tags" },
        { title: "Landing pages" },
        { title: "Youtube Descriptions" },
        { title: "Quora Answer" },
        { title: "Product Descriptions" },
        { title: "Emails" },
        { title: "Text Summary" },
        { title: "Review Responder" },
        { title: "AI Article & Blogs" },
        { title: "Twitter Ads" },
        { title: "Youtube Ads" },
        { title: "Linkedin Ads" },
    ]
    return (
        <>
            <div className="container-fluid mt-5 ads-head">
                <div className="row d-flex justify-content-center">
                    <h1 className='text-white text-center mt-5'>What can Dowrite <br /> for you?</h1>
                    <div className="col-lg-11 mb-4">
                        <div className='row mb-4'>
                            {
                                btnArray.map((t, i) => (
                                    <div className='col-lg-2 col-md-3 col-sm-4 col-6' key={i}><button className='btn ads-btn'>{t.title}</button></div>
                                ))
                            }
                        </div>
                        <p className='text-center text-white'>Over 100 powerful features Generate high-quality articles, blogs, landing pages, Google ads,<br /> Facebook ads, emails, product descriptions, tweets, Instagram captions, and more in seconds</p>

                        <div className='d-flex justify-content-center'>
                            <button className='btn getStarted-btn'>Get started for free</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Ads;
