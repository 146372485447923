import React, { useState } from 'react';
import './AIcontent.css'
const AIcontent: React.FC = () => {
    const [activebtn, setactivebtn] = useState('Describe')
    const [activeIndex, setactiveIndex] = useState(0)


    const btnArray = [
        { title: "Describe" },
        { title: "Generate" },
        { title: "Publish" },
    ]

    const handleClick = () => {
        if (activeIndex < 2) {
            setactivebtn(btnArray[activeIndex + 1].title)
            setactiveIndex(activeIndex + 1)
        } else {
            setactivebtn(btnArray[0].title)
            setactiveIndex(0)
        }
    }
    return (
        <>
            <div className="container-fluid mt-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-10 col-md-10 col-sm-10 col-11">
                        <div className="row pt-5">
                            <h1 className='text-center fw-bold'>How does AI Content Generator work?</h1>
                        </div>
                        <div className='row mt-3'>
                                        <div className='col-lg-5 col-md-4 col-sm-6 col-6 pt-1 d-lg-block d-md-block d-sm-none d-none'>
                                            <div className='d-flex gap-2'>
                                                <div className='hero-number'>1</div>
                                                <button className={`fw-bold head-title ${activebtn == 'Describe' && 'text-primary'}`} onClick={() => setactivebtn('Describe')}>Describe</button>
                                                <div className='hero-div-line' style={{ width: "70%" }}></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-5 col-md-4 col-sm-6 col-6 pt-1 d-lg-block d-md-block d-sm-none d-none'>
                                            <div className='d-flex gap-2'>
                                                <div className='hero-number'>2</div>
                                                <button className={`fw-bold head-title ${activebtn == 'Generate' && 'text-primary'}`} onClick={() => setactivebtn('Generate')}>Generate</button>
                                                <div className='hero-div-line' style={{ width: "57%" }}></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-4 col-sm-12 pt-1 d-lg-block d-md-block d-sm-none d-none'>
                                            <div className='d-flex  gap-1'>
                                                <div className='hero-number'>3</div>
                                                <button className={`fw-bold head-title ${activebtn == 'Publish' && 'text-primary'}`} onClick={() => setactivebtn('Publish')}>Publish</button>
                                            </div>
                                        </div>
                                    </div>
                        <div className='row d-lg-none d-md-none d-sm-block d-block'>
                            <div className='d-flex justify-content-center gap-3' style={{ position: 'relative' }}>
                                <div className='hero-number mt-1'>{activeIndex + 1}</div> <div className='fw-bold fs-5 text-primary'>{btnArray[activeIndex].title}</div>
                                <div style={{ position: 'absolute', right: 20, top: 5 }}>
                                    <i className="fa-solid fa-arrow-right fs-5" onClick={handleClick}></i>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-lg-6 col-md-11'>
                                <h2 className='fw-bold mt-5'>Describe</h2>
                                <p className='mt-2 text-secondary'>Describe your topic (minimum 5 words). Specify the keyword (optional) you wish to rank for an Google. Choose your language from 25+ options. Choose your language from 25+ options</p>
                                <div className='mt-5 mb-5 d-flex position-relative' style={{ gap: "100px" }}>
                                    <img src='https://img.freepik.com/premium-photo/portrait-handsome-anime-boy-avatar-computer-graphic-background-2d-illustration_67092-1980.jpg?w=2000' height={60} width={60} style={{ borderRadius: "50%" }} />
                                    <div className='p-2 aicontentbtn'>
                                        <h5 className='fw-bold mb-0'>+10X</h5> More Productivity
                                    </div>
                                    <div className='position-absolute text-white' style={{ top: 30, left: 45, backgroundColor: "#FF76DE", borderRadius: "10px", padding: "0 3px 0 3px", transform: "rotate(5deg)" }}>Marketer</div>
                                </div>

                                <h2 className='fw-bold'>The future is now!</h2>
                                <p className='mt-2 text-secondary'>Describe your topic (minimum 5 words). Specify the keyword (optional) you wish to rank for an Google. Choose your language from 25+ options. Choose your language from 25+ options</p>
                            </div>
                            <div className='col-lg-6 col-md-11 mt-3'>
                                <div className=' d-flex justify-content-center'>
                                    <div className='shadow p-3 w-75 aicontent-right-div' style={{ borderRadius: "20px" }}>
                                        <p className='text-secondary'>Topic: Financial Freedom</p>
                                        <div className='p-2' style={{ backgroundColor: "#f1f1f1", borderRadius: "5px" }}>
                                            <p className='fw-bold mb-0'>Title</p>
                                            <p className='text-secondary'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                        </div>
                                        <div className='p-2 mt-3' style={{ backgroundColor: "#f1f1f1", borderRadius: "5px" }}>
                                            <p className='fw-bold mb-0'>Intro</p>
                                            <p className='text-secondary'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                                        </div>
                                        <div className='p-2 mt-3' style={{ backgroundColor: "#f1f1f1", borderRadius: "5px" }}>
                                            <p className='fw-bold mb-0'>Outlines</p>
                                            <p className='text-secondary mb-0'>01) Lorem Ipsum is simply dummy text of </p>
                                            <p className='text-secondary mb-0'>02) Lorem Ipsum is simply dummy text of </p>
                                            <p className='text-secondary mb-0'>03) Lorem Ipsum is simply dummy text of </p>
                                            <p className='text-secondary mb-0'>04) Lorem Ipsum is simply dummy text of </p>
                                            <p className='text-secondary mb-0'>05) Lorem Ipsum is simply dummy text of </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default AIcontent;