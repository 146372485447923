import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './CkEditorWrapper.css'
interface CkEditorWrapperProps {
  initialValue: string;
  onChange: (newContent: string) => void;
}

const CkEditorWrapper: React.FC<CkEditorWrapperProps> = ({ initialValue, onChange }) => {
  const handleEditorChange = (event: any, editor: any) => {
    const newContent = editor.getData();
    onChange(newContent);
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      data={initialValue}
      onChange={handleEditorChange}
    />
  );
};

export default CkEditorWrapper;
