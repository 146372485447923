import React, { useState } from 'react';
import './DoWrite.css'
import Image1 from '../../../../assets/images/robotImage.png'
const DoWrite: React.FC = () => {
    const [activeBtn, setactiveBtn] = useState('Writers')

    const data = [
        {
            icon: "fa-solid fa-pen",
            text: "Writers",
            description: 'Use The AI Writer to create long-form blogs and articles; the Sonic Editor (an enhanced version of Google Docs) to write sales emails, essays, reports, and even ebooks. Moreover, you can polish you content to perfection using our AI-driven editing tools: Paraphraser, Expander, and Shortener'
        },
        {
            icon: "fa-solid fa-store",
            text: "Ecommerce stores",
            description: 'Use The AI Writer to create long-form blogs and articles; the Sonic Editor (an enhanced version of Google Docs) to write sales emails, essays, reports, and even ebooks. Moreover, you can polish you content to perfection using our AI-driven editing tools: Paraphraser, Expander, and Shortener'
        },
        {
            icon: "fa-solid fa-volume-high",
            text: "Marketing teams",
            description: 'Use The AI Writer to create long-form blogs and articles; the Sonic Editor (an enhanced version of Google Docs) to write sales emails, essays, reports, and even ebooks. Moreover, you can polish you content to perfection using our AI-driven editing tools: Paraphraser, Expander, and Shortener'
        },
        {
            icon: "fa-solid fa-bell",
            text: "Entrepreneurs",
            description: 'Use The AI Writer to create long-form blogs and articles; the Sonic Editor (an enhanced version of Google Docs) to write sales emails, essays, reports, and even ebooks. Moreover, you can polish you content to perfection using our AI-driven editing tools: Paraphraser, Expander, and Shortener'
        },
    ]
    return (
        <>
            <div className="container-fluid mt-3">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-10 col-md-11 col-sm-11 col-11">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 p-2">
                                <p className='fw-bold' style={{ fontSize: "45px" }}>Dowrite copywriting is for everyone</p>

                                <div className='left-dowrite-div mt-5' style={{ marginRight: "50px" }}>
                                    {data.map((t, i) => (
                                        <div className='mt-3 border' style={{ borderRadius: "15px" }} onClick={()=>setactiveBtn(t.text)}>
                                            <div className={`${t.text == activeBtn ? 'p-3' : 'p-2'} d-flex gap-3`} style={{ backgroundColor: t.text == activeBtn ? '#f1f1f1' : 'transparent', borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}>
                                                <i className={`${t.icon} text-primary fs-4 mt-2`}></i>  <span className={`fs-4 ${t.text == activeBtn ? 'text-primary fw-bold' : 'text-secondary'}`}>{t.text}</span>
                                            </div>
                                            {
                                                t.text == activeBtn && (
                                                    <p className='p-3 text-secondary'>{t.description}</p>
                                                )
                                            }
                                        </div>
                                    ))}
                                    {/* <div className='mt-3'>
                                        {
                                            data.map((t, i) => (
                                                <div key={i} className='p-2 border d-flex gap-3 mt-2' style={{ borderRadius: "10px" }}>
                                                    <i className={`${t.icon} text-primary fs-4 mt-2`}></i> <span className='fs-4 text-seondary'>{t.text}</span>
                                                </div>
                                            ))
                                        }
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 p-2">
                                <p className='mt-2' style={{ fontSize: "25px", color: "gray" }}>Write SEO optimized copy for blogs, essays, Facebook ads, Google Ads, Quora answers, and sales emails to increase clicks, conversions, and sales.</p>

                                <div className='mt-5 shadow p-4' style={{ borderRadius: "20px", position: 'relative' }}>
                                    <div className='article-div' style={{ width: "60%", borderRight: "1px solid lightgray" }}>
                                        <p style={{ fontSize: "17px", fontWeight: "bold", marginBottom: "5px" }}>Article title <i className="fa-solid fa-circle-info" style={{ color: "lightgray" }}></i></p>
                                        <p>Here is the Title of Article </p>
                                        <p style={{ fontSize: "17px", fontWeight: "bold", marginBottom: "5px" }}>Article Intro</p>
                                        <p>Compelling readers to read the article is an art form in itself. And if you don't do it</p>
                                        <p style={{ fontSize: "17px", fontWeight: "bold", marginBottom: "5px" }}>Article Outline</p>
                                        <p>An outline is a tool used to organize written ideas about a topic or thesis into a logical order. Outlines arrange major topics, subtopics, and supporting details.</p>
                                    </div>

                                    <div className='bg-white d-lg-block d-md-block d-sm-none d-none shadow right-dowrite-div' style={{ position: 'absolute', top: -25, right: -90, width: "300px", borderRadius: "20px" }}>
                                        <div className='p-3'>
                                            <div className='d-flex gap-3'>
                                                <i className="fa-solid fa-pen text-primary"></i>
                                                <i className="fa-regular fa-bookmark"></i>
                                                <i className="fa-regular fa-clone"></i>
                                                <i className="fa-regular fa-calendar-plus"></i>
                                            </div>
                                            <p className='mt-1 mb-1 fw-bold'>Book review: The girl in the tower</p>
                                            <p className='mb-0'>Many students define paragraphs in terms of length: a paragraph is a group of at least five sentences, a paragraph is half a page long, etc.</p>
                                        </div>
                                        <div className='p-2 fw-bold' style={{ borderTop: "1px solid lightgray" }}>
                                            1493 words
                                        </div>
                                    </div>

                                    <div className='bg-white d-lg-block d-md-block d-sm-none d-none shadow right-dowrite-div2' style={{ position: 'absolute', bottom: -35, right: -90, width: "300px", borderRadius: "20px" }}>
                                        <div className='p-3'>
                                            <img src={Image1} />
                                            <p className='text-primary fw-bold mt-2 mb-2'>AI Article & Blog Writer</p>
                                            <p className='text-primary mb-0'>Create 1500+ word Blog & Article of the year in 4 steps, with minimal</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DoWrite;
