import React from 'react';
import './Home.css'
import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero'
import DoWrite from '../components/DoWrite/DoWrite';
import AIcontent from '../components/AIcontent/AIcontent';
import Profile from '../components/Profile/Profile';
import Ads from '../components/Ads/Ads';
import Cards from '../components/Cards/Cards';
import Faq from '../components/Faq/Faq';
import ReadyToWrite from '../components/ReadyToWrite/ReadyToWrite';
import Footer from '../components/Footer/Footer';

const Home: React.FC = () => {
  return (
    <div>
        <Header />
        <Hero />
        <DoWrite />
        <AIcontent />
        <Profile />
        <Ads />
        <Cards />
        <br />
        <Faq />
        <br />
        <ReadyToWrite />
        <Footer />
    </div>
  );
};

export default Home;
