import React from 'react';
import logo from './logo.svg';
import './App.css';
import Routes from './pages/Routes';

declare const global: {
  backendUrl: string;
};

// global.backendUrl = 'http://localhost:8000';
global.backendUrl = 'https://blogswritingbackend.unialsolutions.com'

export const BackendUrl: string = global.backendUrl;

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
