import React from 'react';
import './ReadyToWrite.css'
import Image1 from '../../../../assets/images/readyToWrite.png'
const ReadyToWrite: React.FC = () => {
    return (
        <>
            <div className="container-fluid mt-5 ads-head">
                <div className="row d-flex justify-content-center">
                    <h1 className='text-white text-center mt-5'>Ready to write at the speed of light?</h1>
                    <p className='text-center text-white mt-3'>See how easy it can be to write amazing content</p>
                    <div className='d-flex justify-content-center mt-3'>
                        <button className='btn getStarted-btn'>Get started for free</button>
                    </div>

                    <div className='mt-5 d-flex justify-content-center'>
                        <div className='rtw-img' style={{}}>
                            <img src={Image1}  className='w-100' style={{borderTopLeftRadius:"15px",borderTopRightRadius:"15px"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReadyToWrite;
