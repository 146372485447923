import React, { useState } from 'react';
import './Login.css'
import Logo from '../../../assets/images/LogoLogin.png'
import GoogleLogo from '../../../assets/images/google.png'
import MicrosoftLogo from '../../../assets/images/microsoft.png'
import { useNavigate } from 'react-router-dom';
const Login: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className='login-head'>
            <div className="container-fluid">
                <div className="row d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                    <div className="col-lg-8 col-md-10 col-sm-8 col-12">
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 d-lg-block d-md-block d-sm-none d-none'>
                                <div>
                                    <div className='d-flex'>
                                        <img src={Logo} className='img-logo' />
                                        <h1>eraser</h1>
                                    </div>
                                    <h2 className='mt-3 fw-bold'>The Whiteboard for <br /> Engineering Teams</h2>
                                    <div className='mt-4'>
                                        <li className='text-secondary login-li'>Collaborate on technical design</li>
                                        <li className='text-secondary login-li'>Document your systems</li>
                                        <li className='text-secondary login-li'>Diagram-as-code</li>
                                        <li className='text-secondary login-li'>Github Integration</li>
                                        <li className='text-secondary login-li'>Easy UI mockups</li>
                                    </div>
                                    <div className='mt-4'>
                                        <p className='text-secondary'>Learn more at <span className='text-primary'>eraser.io</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12 flexClass'>
                                <div className='card-login shadow-lg'>
                                    <div className='pt-3 flexClass gap-2'>
                                        <img src={Logo} className='img-logo2' /><p className='fs-5 text-secondary'>Sign In</p>
                                    </div>
                                    <div className='mt-3 flexClass'>
                                        <button className='btn-login-signup'><img src={GoogleLogo} className='login-icon' /> <span className='login-text'>Sign Up with Google</span></button>
                                    </div>
                                    <div className='mt-2 flexClass'>
                                        <button className='btn-login-signup'><img src={MicrosoftLogo} style={{ height: '18px', width: "18px" }} /> <span className='login-text' style={{ paddingLeft: '8px' }}>Sign Up with Microsoft</span></button>
                                    </div>
                                    <div className='mt-3 flexClass gap-2'>
                                        <div className='diff-line' style={{background: "linear-gradient(to right, transparent 0%, lightgray 50%, lightgray 100%)"}}></div>
                                        <div className='text-secondary fs-5'>OR</div>
                                        <div className='diff-line' style={{background: "linear-gradient(to left, transparent 0%, lightgray 50%, lightgray 100%)"}}></div>
                                    </div>

                                    <div className='mt-3 flexClass'>
                                        <input type='text' className='login-input' placeholder='Email' />
                                    </div>
                                    <div className='mt-2 flexClass'>
                                        <input type='text' className='login-input' placeholder='Password' />
                                    </div>
                                    <div className='mt-3 flexClass'>
                                        <p className='text-primary' style={{fontSize:'14px'}}>Forgot my password</p>
                                    </div>
                                    <div className='mt-2 flexClass'>
                                        <button className='login-btn'>Sign In</button>
                                    </div>

                                    <div className='mt-3 mb-3 flexClass'>
                                        <p className='text-primary' style={{fontSize:'14px'}} onClick={()=> navigate('/signup')}>New to Eraser? Sign up for free.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Login;