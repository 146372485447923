import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Frontend from './Frontend';
import Login from './Authentication/Login/Login';
import SignUp from './Authentication/Signup/SignUp';

const AppRoutes: React.FC = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Frontend />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/signup' element={<SignUp />} />
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default AppRoutes;
